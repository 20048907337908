<template>
  <section
    :id="slice.primary.reference"
    :data-slice-type="slice.slice_type"
    :data-slice-variation="slice.variation"
    class="clamped--text cms"
  >
    <div class="faq-list">
      <div v-for="(item, index) in slice.items"
           :key="index"
           class="question"
           :class="{'active': index === activeQuestionIndex}">
        <div class="question-title" @click="e => toggleCard(e, index)">
          <div class="question-title__info">
            <svg xmlns="http://www.w3.org/2000/svg"
                 x="0px"
                 y="0px"
                 width="24"
                 height="24"
                 viewBox="0 0 24 24">
              <path d="M 12 0 C 5.371094 0 0 5.371094 0 12 C 0 18.628906 5.371094 24 12 24 C 18.628906 24 24 18.628906 24 12 C 24 5.371094 18.628906 0 12 0 Z M 12 2 C 17.523438 2 22 6.476563 22 12 C 22 17.523438 17.523438 22 12 22 C 6.476563 22 2 17.523438 2 12 C 2 6.476563 6.476563 2 12 2 Z M 12 5.8125 C 11.816406 5.8125 11.664063 5.808594 11.5 5.84375 C 11.335938 5.878906 11.183594 5.96875 11.0625 6.0625 C 10.941406 6.15625 10.851563 6.285156 10.78125 6.4375 C 10.710938 6.589844 10.6875 6.769531 10.6875 7 C 10.6875 7.226563 10.710938 7.40625 10.78125 7.5625 C 10.851563 7.71875 10.941406 7.84375 11.0625 7.9375 C 11.183594 8.03125 11.335938 8.085938 11.5 8.125 C 11.664063 8.164063 11.816406 8.1875 12 8.1875 C 12.179688 8.1875 12.371094 8.164063 12.53125 8.125 C 12.691406 8.085938 12.816406 8.03125 12.9375 7.9375 C 13.058594 7.84375 13.148438 7.71875 13.21875 7.5625 C 13.289063 7.410156 13.34375 7.226563 13.34375 7 C 13.34375 6.769531 13.289063 6.589844 13.21875 6.4375 C 13.148438 6.285156 13.058594 6.15625 12.9375 6.0625 C 12.816406 5.96875 12.691406 5.878906 12.53125 5.84375 C 12.371094 5.808594 12.179688 5.8125 12 5.8125 Z M 10.78125 9.15625 L 10.78125 18.125 L 13.21875 18.125 L 13.21875 9.15625 Z"></path>
            </svg>
          </div>
          <div class="question-title__text">{{ item.title }}</div>
          <div class="plus-minus">
            <span></span>
            <span></span>
          </div>
        </div>
        <PrismicRichText :field="item.content" class="question-text"/>
      </div>
    </div>
  </section>
</template>

<script>
import { getSliceComponentProps } from "@prismicio/vue/components";

export default {
  // The array passed to `getSliceComponentProps` is purely optional.
  // Consider it as a visual hint for you when templating your slice.
  props: getSliceComponentProps(["slice", "index", "slices", "context", "selected", "activeQuestionIndex"]),
  data () {
    return {
      activeQuestionIndex: 0
    };
  },
  methods: {
    toggleCard(evt, index) {
      if (index === this.activeQuestionIndex) {
        this.activeQuestionIndex = -1;
      } else {
        this.activeQuestionIndex = index;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.question {
  margin-top: 1rem;
  padding: 1rem 2rem 1rem;
  border: $border-width solid $black;
  border-radius: 2.5rem;

  background-color: rgba(var(--color-blue-rgb), 0.1);
}

.question-title {
  font-weight: bold;
  display: flex;
  flex-wrap: wrap;
  font-size: 1.5rem;
  align-content: center;
}

.question-title__info {
  padding-top: 5px;
  fill: rgba(var(--color-blue-rgb));
}

.question-title > * {
  align-content: center;
}

.question-title__info {
  padding-right: 0.75rem;
}

.question-title__text {
  flex: 3;
}

.question-text {
  transform: scaleY(0);
  transform-origin: top;
  transition: transform 0.1s ease;
  position: absolute;
}

.active .question-text {
  transform: scaleY(1);
  position: relative;
}

.plus-minus {
  color: #000;
  width: 50px;
  height: 50px;
  border: 0;
  font-size: 1.5em;
  position: relative;
}

.plus-minus span {
  position: absolute;
  transition: 0.1s ease;
  background: #000;
  border-radius: 2px;
}

/* Create the "+" shape by positioning the spans absolutely */
.plus-minus span:first-child {
  top: 30%;
  bottom: 30%;
  width: 7.5%;
  left: 45%;
}

.plus-minus span:last-child {
  left: 27.5%;
  right: 30%;
  height: 7.5%;
  top: 45%;
}

.active .plus-minus span {
  transform: rotate(90deg);
}

.active .plus-minus span:last-child  {
  left: 50%;
  right: 50%;
}
</style>
